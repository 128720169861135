const SiteHeader = ({ className }) => (
	<header className={className}>
		<div className="site-header__logo">
			<img src="/logo.png" alt="molli logo"></img>
		</div>
		<div className="site-sub-header flex items-end">
			<img src="/sub-logo.png" alt="precision surgery made simpler"></img>
		</div>
	</header>
);

export default SiteHeader;
