import "./App.css";
import { useState, useRef, useEffect, useMemo } from "react";
import { select } from "d3-selection";
import useDynamicRefs from "use-dynamic-refs";

import SiteHeader from "./components/SiteHeader";
import PenWrapper from "./components/Pen-v1";
import PadWrapper from "./components/Pad";
import MetalLocator from "./components/MetalLocator";
import SysInfo from "./components/SysInfo";
import MolliInfo from "./components/MolliInfo";
import * as Tone from "tone";

function App() {
  const [isPenMoving, setIsPenMoving] = useState(false);
  const [prevDistance, setPrevDistance] = useState([]);
  const [curDistance, setCurDistance] = useState(0);
  const [isSysInfoVisible, setIsSysInfoVisible] = useState(true);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [currentTone, setCurrentTone] = useState(null);
  const [synth, setSynth] = useState(null);
  const [context, setContext] = useState(null);
  const [gainNode, setGain] = useState(null);
  const [getRef, setRef] = useDynamicRefs();

  const penRef = useRef(null);
  const metalRef = useRef(null);

  // tone list you need to play
  const audioTracks = useMemo(
    () => ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"],
    []
  );

  const audioKey = useMemo(
    () => ({
      1: "A3",
      2: "A#3",
      3: "B3",
      4: "C4",
      5: "C#4",
      6: "D4",
      7: "D#4",
      8: "E4",
      9: "F4",
      10: "F#4",
      11: "G4",
      12: "G#4",
      13: "A4",
    }),
    []
  );

  // Tones are 1 key up here
  // const audioKey = useMemo(
  // 	() => ({
  // 		1: "A4",
  // 		2: "A#4",
  // 		3: "B4",
  // 		4: "C5",
  // 		5: "C#5",
  // 		6: "D5",
  // 		7: "D#5",
  // 		8: "E5",
  // 		9: "F5",
  // 		10: "F#5",
  // 		11: "G5",
  // 		12: "G#5",
  // 		13: "A5",
  // 	}),
  // 	[]
  // );

  const initPlayer = async () => {
    if (synth) return;
    // For MP3s
    // const synthInst = new Tone.Sampler(
    // 	{
    // 		A4: "1.mp3",
    // 		"A#4": "2.mp3",
    // 		B5: "3.mp3",
    // 		C5: "4.mp3",
    // 		"C#5": "5.mp3",
    // 		D5: "6.mp3",
    // 		"D#5": "7.mp3",
    // 		E5: "8.mp3",
    // 		F5: "9.mp3",
    // 		"F#5": "10.mp3",
    // 		G5: "11.mp3",
    // 		"G#5": "12.mp3",
    // 		A6: "13.mp3",
    // 	},
    // 	{
    // 		baseUrl: "/music/",
    // 	}
    // ).toDestination();
    const synthInst = new Tone.Synth().toDestination();
    await Tone.start();
    Tone.loaded().then(() => {
      const contextInstance = new Tone.Context({ latencyHint: "interactive" });
      const gainNodeInstance = new Tone.Gain(0).toDestination();
      synthInst.chain(new Tone.Volume(-10), Tone.Destination);
      Tone.getContext().lookAhead = 0;
      synthInst.sync();
      Tone.Transport.start();
      setGain(gainNodeInstance);
      setSynth(synthInst);
      setContext(contextInstance);
    });
  };

  const stopPlayer = async () => {
    if (!synth || !currentTone) return true;
    gainNode.gain.setTargetAtTime(0, context.currentTime, 0.015);
    synth.triggerRelease();
    setCurrentTone(null);
    return true;
  };

  const playTrack = async (track) => {
    if (!synth) return false;
    gainNode.gain.setTargetAtTime(1, context.currentTime, 0.3);
    synth.triggerAttack(audioKey[track]);
    setCurrentTone(audioKey[track]);
    return true;
  };

  // const setVolume = (volume) => {
  // 	new Tone.Volume(volume).toDestination();
  // };

  useEffect(() => {
    if (!synth) return;
    if (currentTrack < 0) {
      if (currentTone) {
        stopPlayer();
      }
      return;
    }

    if (currentTrack > 0) {
      if (currentTone && currentTone !== audioKey[currentTrack]) {
        synth.setNote(audioKey[currentTrack]);
        setCurrentTone(audioKey[currentTrack]);
      } else {
        playTrack(currentTrack);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack]);

  useEffect(() => {
    setPrevDistance([curDistance, ...prevDistance.slice(0, 10)]);
    let currentAudioRef = getRef(`${currentTrack}`);
    if (!currentAudioRef) return;
    // const avgDistance = prevDistance.reduce((acc, cur) => acc + cur, 0) / prevDistance.length;
    if (isPenMoving && curDistance < 80) {
      // currentAudioRef.current.volume = 1;
      // setVolume(1);
    } else if (isPenMoving && curDistance < 191.19) {
      // currentAudioRef.current.volume = 1 - curDistance / 160;
      // setVolume(1 - curDistance / 195);
    } else if (curDistance > 191.19) {
      // currentAudioRef.current?.pause();
      stopPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curDistance, isPenMoving, currentTrack]);

  const handleOpenInfo = () => {
    setIsSysInfoVisible(true);
  };

  const closeSysInfo = () => {
    setIsSysInfoVisible(false);
    initPlayer();
    stopPlayer();
  };

  const handlePenMove = ({ x, y, isMoving }) => {
    setIsPenMoving(true);
    if (!isMoving) {
      setIsPenMoving(false);
      return;
    }

    const penBoundingRect = penRef.current.getBoundingClientRect();
    const mentalDom = metalRef.current.getBoundingClientRect();
    const ripplesDom = select(".ripples").node();
    const padBigCircleDom = select(".st367");
    const padSmallCircleDom = select(".st370");
    const ipadDistanceDom = select(".st364");
    const molliPadDom = select(".st367");
    const distanceGreatIcon = select("#distance-great-icon");

    const dx =
      penBoundingRect.x -
      8 -
      mentalDom.x +
      penRef.current.getBoundingClientRect().width / 2 -
      metalRef.current.getBoundingClientRect().width / 2;
    const dy = penBoundingRect.y - mentalDom.y - 6;
    const distance = Math.sqrt(dx * dx + dy * dy);

    const r1 = ripplesDom.clientWidth / 2; // metal åœ†çš„åŠå¾„
    const r3 = padBigCircleDom.attr("r"); // ipad å¤§åœ†çš„åŠå¾„
    const x3 = padBigCircleDom.attr("cx"); // ipad å¤§åœ†çš„xåæ ‡
    const y3 = padBigCircleDom.attr("cy"); // ipad å¤§åœ†çš„yåæ ‡

    if (distance > r1) {
      setCurrentTrack(-1);
    }

    if (distance === r1) {
      setCurrentTrack(audioTracks[0]);
    }

    if (distance < r1) {
      molliPadDom.style("fill", "#04B6ED");
      const percent = distance / r1;

      // const fiveTones = 20;
      const thirteenTones = 7.69;
      let cTrack = parseInt((percent * 100) / thirteenTones);
      const playList = JSON.parse(JSON.stringify(audioTracks));
      playList.reverse();
      setCurrentTrack(playList[cTrack]);

      // let p = Math.round(percent * 50);
      let ipadCount =
        Math.round(percent * 50) <= 2 ? 0 : Math.round(percent * 50);

      ipadDistanceDom.text(`${ipadCount} mm`);
      distanceGreatIcon.text("");

      const rate = r3 / r1;
      padSmallCircleDom.attr("cx", +x3 + dx * rate).attr("cy", +y3 + dy * rate);

      if (ipadCount <= 2) {
        padSmallCircleDom.style("fill", "#00F083");
      } else {
        padSmallCircleDom.style("fill", "#FFF");
      }

      if (ipadCount >= 40) {
        padSmallCircleDom.style("opacity", 0);
      } else if (ipadCount < 40 && percent >= 0.7) {
        padSmallCircleDom.style("opacity", 1 - percent);
      } else {
        padSmallCircleDom.style("opacity", 1);
      }
    } else {
      molliPadDom.style("fill", "#B8EDFE");
      padSmallCircleDom.style("opacity", 0);
      ipadDistanceDom.text("50 mm");
      distanceGreatIcon.text(">");
      stopPlayer();
    }
    setCurDistance(distance);
  };

  return (
    <div className="App grid h-screen w-screen items-center justify-items-center overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex flex-col overflow-hidden 2xl:h-5/6 2xl:w-5/6 2xl:gap-4">
        <SiteHeader className="site-header hidden justify-between 2xl:flex" />

        <div className="flex h-full w-full flex-auto select-none bg-primary">
          <div className="relative flex h-full w-full flex-1 overflow-hidden">
            <PadWrapper className="pad sm:5/6 md:5/6 absolute left-8 top-5 z-20 w-1/3 sm:top-20" />

            <MetalLocator ref={metalRef} />

            <PenWrapper
              initPlayer={initPlayer}
              width={80}
              ref={penRef}
              handlePenMove={handlePenMove}
            />

            <MolliInfo
              className="absolute bottom-4 left-4 cursor-pointer"
              handleClick={handleOpenInfo}
            ></MolliInfo>

            <div className="marker absolute left-[50%] h-full w-0 bg-white"></div>
          </div>
        </div>

        {audioTracks.map((track, index) => {
          return (
            <audio
              key={index}
              ref={setRef(track)}
              loop={currentTrack === track}
              src={`/music/${track}.mp3`}
            />
          );
        })}

        <SysInfo
          isSysInfoVisible={isSysInfoVisible}
          closeSysInfo={closeSysInfo}
        ></SysInfo>
      </div>
    </div>
  );
}

export default App;
