import { ReactComponent as Pad } from './molli-pad-v4.svg';

const PadWrapper = ({ className, ...props }) => {
  return (
    <div className={className}>
      <Pad {...props} width="100%" />
    </div>
  );
}

export default PadWrapper;