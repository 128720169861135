import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === process.env.REACT_APP_ACCESS_PASSWORD) {
            const redirectTo = location?.search ? decodeURIComponent(new URLSearchParams(location.search).get("redirect")) : null;
            window.localStorage.setItem("access-molli", true);
            if (redirectTo) {
                navigate(redirectTo);
            } else {
                navigate("v3");
            }
        } else {
            alert("Incorrect Password");
        }
    };

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Molli Logo"
              src="/logo.png"
              className="mx-auto h-10 w-auto object-contain"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter Password to View
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form action="#" onSubmit={handleSubmit} method="POST" className="space-y-6">
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                  onChange={handleChange}
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <button
                    onClick={handleSubmit}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Enter
                </button>
              </div>
            </form>
  
          </div>
        </div>
      </>
    )
  }