import { useRef, useEffect, forwardRef } from "react";
import { isMobile } from "react-device-detect";

import { select } from "d3-selection";
import { drag } from "d3-drag";

import { ReactComponent as Pen } from "./pen-new.svg";

const PenWrapper = forwardRef(
  ({ className, handlePenMove, initPlayer, ...props }, ref) => {
    const dragableDiv = useRef(null);
    const dragStart = useRef(null);

    const dragStartHandler = function (event) {
      select(this).raise().style("cursor", "pointer").classed("active", true);
      dragStart.current = {
        x: event.x,
        y: event.y,
        left: parseInt(getComputedStyle(dragableDiv.current).left),
        top: parseInt(getComputedStyle(dragableDiv.current).top),
      };
    };

    const dragMoveHandler = function (event) {
      let left =
        parseInt(getComputedStyle(dragableDiv.current).left) +
        event.x -
        dragStart.current.x;
      let top =
        parseInt(getComputedStyle(dragableDiv.current).top) +
        event.y -
        dragStart.current.y;

      if (!isMobile) {
        if (left < 400) {
          left = 400;
        }
      } else {
        if (top < 300) {
          top = 300;
        }
      }

      requestAnimationFrame(() => {
        select(this).style("left", `${left}px`).style("top", `${top}px`);

        // reset drag start
        dragStart.current = {
          x: event.x,
          y: event.y,
          left: parseInt(getComputedStyle(dragableDiv.current).left),
          top: parseInt(getComputedStyle(dragableDiv.current).top),
        };

        handlePenMove({ x: left, y: top, isMoving: true });
      });
    };

    const dragEndHandler = function (event) {
      select(this).classed("active", false).style("corsor", "default");
      handlePenMove({ x: event.x, y: event.y, isMoving: false });
    };

    useEffect(() => {
      const dragBehavior = drag()
        .on("start", dragStartHandler)
        .on("drag", dragMoveHandler)
        .on("end", dragEndHandler);

      select(dragableDiv.current).call(dragBehavior);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        ref={dragableDiv}
        className="pen__wrapper absolute -bottom-[500px] right-24 z-[100] w-[80px] sm:-bottom-[500px]"
      >
        <Pen {...props} ref={ref} />
      </div>
    );
  }
);

export default PenWrapper;
