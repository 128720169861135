import { useEffect, useRef } from "react";
import "./ripple.scss";
// import * as d3 from "d3";

const Ripple = () => {
	// eslint-disable-next-line no-unused-vars
	const svgRef = useRef(null);

	useEffect(() => {}, []);

	return (
		<div className="ripples z-1 relative h-64 w-64 md:h-96 md:w-96">
			<div
				id="five"
				className="z-2 absolute bottom-0 left-0 right-0 top-0 m-auto to-blue-300"
			>
				13
			</div>
			{/* <div id="four" className="to-blue-500">
				12
			</div>
			<div id="four" className="to-blue-500">
				11
			</div>
			<div id="four" className="to-blue-500">
				10
			</div>
			<div id="four" className="to-blue-500">
				9
			</div>
			<div id="four" className="to-blue-500">
				8
			</div>
			<div id="four" className="to-blue-500">
				7
			</div>
			<div id="four" className="to-blue-500">
				6
			</div>
			<div id="four" className="to-blue-500">
				5
			</div>
			<div id="four" className="to-blue-500">
				4
			</div> */}
			<div id="four" className="to-blue-500">
				4
			</div>
			<div id="three" className="to-blue-700">
				3
			</div>
			<div id="two" className="to-blue-800">
				2
			</div>
			<div id="one" className="to-blue-900">
				1
			</div>
		</div>
	);
};

export default Ripple;
