import { useRef, useEffect, forwardRef, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";

import { select } from "d3-selection";
import { drag } from "d3-drag";

// import { ReactComponent as Pen } from "./pen-new.svg";
import { ReactComponent as Pen } from "./pen-v2.svg";
import { ReactComponent as SidePen } from "./pen-sideview.svg";

const PenWrapper = forwardRef(
  (
    {
      className,
      handlePenMove,
      initPlayer,
      isFrontView,
      penRef,
      metalRef,
      ...props
    },
    ref
  ) => {
    const [penStyle, setPenStyle] = useState({});
    const dragableDiv = useRef(null);
    const dragStart = useRef(null);

    const calcLeftPosition = useCallback(() => {
      const wrapper = select(".wrapper-parent").node();
      const metalRefDom = metalRef.current.getBoundingClientRect();

      if (!wrapper || !metalRefDom) return;
      const wrapperRect = wrapper.getBoundingClientRect();

      return metalRefDom.left - wrapperRect.left;
    }, [metalRef]);

    const calcTopPosition = useCallback(() => {
      const wrapper = select(".wrapper-parent").node();
      const locator = select(".locator").node();
      const metalRefDom = metalRef.current.getBoundingClientRect();

      if (!wrapper || !metalRefDom || !locator) return;
      const wrapperRect = wrapper.getBoundingClientRect();

      return metalRefDom.top - wrapperRect.top;
    }, [metalRef]);

    const calcMaxPositions = useCallback(
      (eventTop, eventLeft) => {
        let left = eventLeft;
        let top = eventTop;
        const wrapper = select(".wrapper-parent").node();
        const pad = select(".st13").node();
        if (isMobile) {
          // Mobile view
          if (wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            // Max left
            if (left > wrapperRect.width - 50) {
              left = wrapperRect.width - 50;
            }
            // Max right
            if (left < -20) {
              left = -20;
            }
            // Max bottom
            if (top > wrapperRect.height - 25) {
              top = wrapperRect.height - 25;
            }
          }
          // Max top (front view)
          if (isFrontView) {
            if (pad) {
              const padRect = pad.getBoundingClientRect();

              if (padRect.top > top) {
                top = padRect.top;
              }
            } else if (top < 300 && !pad) {
              top = 300;
            }
            // Max top (side view)
          } else {
            if (top < 1) {
              top = 1;
            }
          }
        } else {
          // Desktop view
          if (wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            // Max right
            if (left > wrapperRect.width - 100) {
              left = wrapperRect.width - 100;
            }
            // Max top
            if (top < 1) {
              top = 1;
            }
            // Max bottom
            if (top > wrapperRect.height - 25) {
              top = wrapperRect.height - 25;
            }
          }
          // Max left (front view)
          if (pad && isFrontView) {
            const padRect = pad.getBoundingClientRect();
            if (left < padRect.width + 30) {
              left = padRect.width + 30;
            }
          }
        }
        return { left, top };
      },
      [isFrontView]
    );

    const dragStartHandler = function (event) {
      select(this).raise().style("cursor", "pointer").classed("active", true);
      dragStart.current = {
        x: event.x,
        y: event.y,
        left: isFrontView
          ? parseInt(getComputedStyle(dragableDiv.current).left)
          : isMobile
          ? calcLeftPosition() + 45
          : calcLeftPosition() + 25,
        top: parseInt(getComputedStyle(dragableDiv.current).top),
      };
    };

    const dragMoveHandler = function (event) {
      const maxLeft = Math.floor(calcLeftPosition() + 19);
      const currentLeft =
        parseInt(getComputedStyle(dragableDiv.current).left) +
        event.x -
        dragStart.current.x;
      let left = !isFrontView && currentLeft < maxLeft ? maxLeft : currentLeft;

      let top =
        parseInt(getComputedStyle(dragableDiv.current).top) +
        event.y -
        dragStart.current.y;

      const { left: newLeft, top: newTop } = calcMaxPositions(top, left);
      left = newLeft;
      top = newTop;

      requestAnimationFrame(() => {
        select(this).style("left", `${left}px`).style("top", `${top}px`);

        // reset drag start
        dragStart.current = {
          x: event.x,
          y: event.y,
          left: isFrontView
            ? parseInt(getComputedStyle(dragableDiv.current).left)
            : isMobile
            ? calcLeftPosition() + 45
            : calcLeftPosition() + 25,
          top: parseInt(getComputedStyle(dragableDiv.current).top),
        };

        handlePenMove({
          x: left,
          y: top,
          isMoving: true,
        });
      });
    };

    const dragEndHandler = function (event) {
      select(this).classed("active", false).style("corsor", "default");
      handlePenMove({
        x: event.x,
        y: event.y,
        isMoving: false,
      });
    };

    const handlePenStyle = useCallback(() => {
      if (isFrontView) {
        setPenStyle({});
      } else {
        const maxLeft = Math.floor(calcLeftPosition());
        let left = isMobile ? maxLeft + 45 : maxLeft + 25;
        const wrapper = select(".wrapper-parent").node();
        if (wrapper) {
          const wrapperRect = wrapper.getBoundingClientRect();
          left = isMobile ? wrapperRect.width - 125 : wrapperRect.width - 200;
        }
        setPenStyle({
          left: `${left}px`,
          top: `${calcTopPosition()}px`,
        });
      }
    }, [calcLeftPosition, calcTopPosition, isFrontView]);

    useEffect(() => {
      const dragBehavior = drag()
        .on("start", dragStartHandler)
        .on("drag", dragMoveHandler)
        .on("end", dragEndHandler);
      select(dragableDiv.current).call(dragBehavior);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFrontView]);

    useEffect(() => {
      handlePenStyle();
    }, [isFrontView, handlePenStyle]);

    useEffect(() => {
      window.addEventListener("resize", handlePenStyle);
      return () => window.removeEventListener("resize", handlePenStyle);
    }, [handlePenStyle]);

    return (
      <div
        ref={dragableDiv}
        className={`${
          isFrontView
            ? "-bottom-[375px] right-[1svh] w-[80px] sm:-bottom-[400px] sm:right-28"
            : "right-[-20svh] top-[80svh] w-[400px]  sm:-right-[30px] sm:top-[60%] "
        } pen__wrapper absolute z-[100]  `}
        style={penStyle}
      >
        {isFrontView ? (
          <Pen {...props} ref={ref} />
        ) : (
          <SidePen {...props} ref={ref} />
        )}
      </div>
    );
  }
);

export default PenWrapper;
