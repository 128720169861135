import * as React from "react";
// import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ isSysInfoVisible, closeSysInfo }) {
	// const [inputValue, setInputValue] = useState("");

	// const handleInputChange = (event) => {
	// 	setInputValue(event.target.value);
	// };

	const handleSubmit = (event) => {
		event.preventDefault();
		closeSysInfo();
	};

	return (
		<div>
			<BootstrapDialog
				aria-labelledby="customized-dialog-title"
				open={isSysInfoVisible}
			>
				<form onSubmit={handleSubmit}>
					<BootstrapDialogTitle
						id="customized-dialog-title"
						onClose={handleSubmit}
					>
						<b className="font-open-sans">Instructions</b>
					</BootstrapDialogTitle>
					<DialogContent dividers>
						<Typography gutterBottom>
							Touch and drag the Wand to control its position.
						</Typography>
						<Typography gutterBottom>
							Use the Wand to locate the marker and view the response on screen.
						</Typography>
					</DialogContent>
					<div className="flex justify-center py-2 text-center">
						<Button className="font-open-sans" autoFocus type="submit">
							Begin
						</Button>
					</div>
				</form>
			</BootstrapDialog>
		</div>
	);
}
