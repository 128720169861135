import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createHashRouter, RouterProvider, redirect } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import AppV1 from "./App-v1";
import AppV3 from "./App-v3";
import Login from "./Login";

const protectRoute = (url) => {
  if (!process.env.REACT_APP_PASSWORD_PROTECTED) {
    return true;
  }
  if (
    !localStorage.getItem("access-molli") ||
    localStorage.getItem("access-molli") !== "true"
  ) {
    return redirect(`/login?redirect=${encodeURIComponent(url)}`);
  }
  return true;
};

const router = createHashRouter([
  {
    path: "/",
    element: <AppV1 />,
  },
  {
    path: "/v2",
    element: <App />,
    loader: (props) => protectRoute("/v2"),
  },
  {
    path: "/v3",
    element: <AppV3 />,
    loader: () => protectRoute("/v3"),
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
