import React, { forwardRef } from "react";
import Ripple from "./Ripple";

const MetalLocator = forwardRef((props, ref) => {
  return (
    <div
      className={`${
        props?.isFrontView ? "" : "loc-side-view"
      } locator z-1 absolute right-1/3 top-3 m-auto flex h-64 w-64 items-center justify-center md:h-96 md:w-96`}
    >
      <Ripple />

      <div className="absolute z-10 h-6 w-6" ref={ref}>
        <img
          src="/metal-locator.png"
          alt="metal locator"
          className="absolute h-5 w-5 translate-x-[2px] translate-y-[4px]"
        />
      </div>
    </div>
  );
});

export default MetalLocator;
