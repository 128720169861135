import { ReactComponent as Info } from './molli-info.svg';

const MolliInfo = ({ className, handleClick, ...props }) => {
  return (
    <div className={className} onClick={handleClick}>
      <Info {...props} width="40" />
    </div>
  );
}

export default MolliInfo;